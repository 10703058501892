@import "fonts";

html {
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-base;
  font-weight: 300;
  background: #fbfbfb;
  overflow-y: scroll;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-base;
  font-weight: 400;
}

h1 {
  font-size: 30px;
}

input[type=text]::-ms-clear {
  display: none;
}

.page-content {
  padding: 10px 40px 40px;
  margin-top: 50px;

  .form-container {
    width: 50%;

    .form-section {
      padding-left: 30px;
    }

    .form-section-title {
      margin: 70px 0 0;
    }

    .text-field {
      width: 100%;
    }
  }
}

.actions {
  margin: 40px 0;
  text-align: right;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .col {
    flex: 1;
    padding: 0 10px;
  }
}

.noraBar {
  background-image: linear-gradient(to right, #82f4e4, #667891);
  height: 55px;
  border-radius: 4px;
  margin: 15px;
  position: relative;
}

.noraScoreActive {
  height: 70px;
  background-image: linear-gradient(to right, #82f4e4, #667891);
  text-align: center;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
  font-weight: 900;
  color: #ffffff;
  line-height: 70px;
  position: absolute;
  margin-top: -8px;
  width: 25%;

  &.A0 {
    left: 0;
  }

  &.A1 {
    left: 25%;
  }

  &.A2 {
    left: 50%;
  }

  &.A3 {
    left: 75%;
    width: 26%;
  }
}

.noraScore {
  text-align: center;
  border-right: solid 1px;
  border-color: #ffffff;
  font-weight: 900;
  color: #ffffff;
  line-height: 55px;
  position: absolute;
  width: 25%;

  &.A0 {
    left: 0;
  }

  &.A1 {
    left: 25%;
  }

  &.A2 {
    left: 50%;
  }

  &.A3 {
    left: 75%;
  }
}

.datePicker > div:nth-child(1) > label {
  color: #5c5a5f !important;
  font-size: 15px;
  font-weight: 400;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  right: -25%;
  bottom: 125%;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #555;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
