$font-dir: '../assets/fonts/';
$font-family-base: 'fontfont-ff-mark-pro', sans-serif;

@mixin generate-font($name, $type, $weight, $style) {
    $font-full-path: '#{$font-dir}/#{$name}-#{$type}-#{$style}';
    @if $style == normal {
        $font-full-path: '#{$font-dir}/#{$name}-#{$type}';
    }
    @font-face {
        font-family: '#{$name}';
        src: url('#{$font-full-path}.woff2') format('woff2'),
        url('#{$font-full-path}.woff') format('woff');
        font-weight: $weight;
        font-style: $style;
        font-display: block;
    }
}

@include generate-font('fontfont-ff-mark-pro', 'bold', 700, normal);
@include generate-font('fontfont-ff-mark-pro', 'medium', 500, normal);
@include generate-font('fontfont-ff-mark-pro', 'regular', 400, normal);
@include generate-font('fontfont-ff-mark-pro', 'regular', 400, italic);
@include generate-font('fontfont-ff-mark-pro', 'light', 300, normal);
