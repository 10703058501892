.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  height: 60px;
  padding: 55px 25px 25px;
  color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.MuiFormHelperText-root.Mui-error {
  font-weight: 500;
}
